@import '../../utils/variables';


@media (max-width: 991.98px) {
	.mod-building {
		display: none;
	}
}

.g-color-cyan,
.svg-color-cyan path {
	cursor: pointer !important;
	transition: all 0.3s ease-in-out;
	fill: $cyanSvg;
	fill-opacity: 0;

	&:hover {
		fill-opacity: .75;
	}
}

.g-color-orange,
.svg-color-orange path {
	cursor: pointer !important;
	transition: all 0.3s ease-in-out;
	fill: $orangeSvg;
	fill-opacity: 0;

	&:hover {
		fill-opacity: .75;
	}
}

.g-color-blue,
.svg-color-blue path {
	cursor: pointer !important;
	transition: all 0.3s ease-in-out;
	fill: $blueSvg;
	fill-opacity: 0;

	&:hover {
		fill-opacity: .75;
	}
}

.g-color-magenta,
.svg-color-magenta path {
	cursor: pointer !important;
	transition: all 0.3s ease-in-out;
	fill: $magentaSvg;
	fill-opacity: 0;

	&:hover {
		fill-opacity: .75;
	}
}


.building {
	&__custom-page {
		width: 1110px;
		height: 572px;
		background-repeat: no-repeat;
		background-position: center;
		background-size: contain;
		margin: auto;

		.h1 {

			&:after,
			&:before {
				content: none;
				text-align: center;
			}
		}

		.btn-arrow-wrapper {
			.btn-arrow {
				margin: 0px !important;
				position: relative;
				top: -40px;
			}
		}
	}

	&__custom-title {
		font-size: 4.496rem;
		font-weight: 300;
		color: $primary;
		text-align: center;
		position: absolute;
		left: 50%;
		transform: translateX(-50%);
		overflow: hidden;
	}
}

.building__page {
	width: 820px;
	height: 572px;
	background-repeat: no-repeat;
	background-position: center;
	margin: auto
}

.building__page path.room-free {
	fill: #52b669 !important;
	fill-opacity: .5 !important
}

.building__page path.room-reserved {
	fill: #ffffa7 !important;
	fill-opacity: .5 !important
}

.building__page path.room-sold {
	fill: #df5765 !important;
	fill-opacity: .5 !important
}

.building__page path:hover {
	cursor: pointer;
	fill-opacity: .75 !important
}

@media (min-width: 1200px) and (max-height: 800px) {
	.building__page {
		width: 520px;
		height: 360px;
		background-size: 100%;
		overflow: hidden
	}

	.building__page path {
		transform: scale(0.635)
	}
}

.building__nav .nav-item {
	flex-grow: 1;
	text-align: center
}

.building__nav .nav-item .nav-link {
	text-transform: uppercase;
	text-decoration: none;
	padding-top: 0rem;
	padding-bottom: 2rem;
	letter-spacing: 0.03em;
	line-height: 2.9rem;
	background-color: transparent !important;
	color: #7A6650 !important;
	font-weight: bold;
	transition: all 0.2s ease-in-out
}

.building__nav .nav-item .nav-link.active {
	box-shadow: 0px 2px 0px 0px #7a6650
}

.building__nav .nav-item .nav-link:hover {
	box-shadow: 0px 2px 0px 0px #7a6650
}

.building__nav li {
	cursor: pointer !important
}

@media (min-width: 1200px) {
	.building__nav--top {
		margin-bottom: 7rem !important
	}
}

@media (min-width: 1200px) and (max-height: 800px) {
	.building__nav--top {
		margin-bottom: 3rem !important
	}
}

@media (max-width: 1199.98px) {
	.building__nav--top {
		margin-bottom: 1rem !important
	}
}

@media (min-width: 1200px) {
	.building__nav--bottom {
		margin-top: 7rem;
		margin-bottom: 7rem;
	}
}

@media (min-width: 1200px) and (max-height: 800px) {
	.building__nav--bottom {
		margin-top: 3rem !important
	}
}

@media (max-width: 1199.98px) {
	.building__nav--bottom {
		margin-top: 1rem !important
	}
}

@media(min-width:992px) and (max-width:1200px) {

	.building__custom-page {
		width: 930px;

		svg {
			width: 930px;
		}

		.g-color-magenta {
			transform: scale(0.85) translate(-15px, 50px);
		}

		.g-color-cyan {
			transform: scale(0.85) translate(-6px, 50px);
		}

		.g-color-blue {
			transform: scale(.85) translate(-5px, 50px);
		}

		.g-color-orange {
			transform: scale(.85) translate(-5px, 50px);
		}
	}

}

.btn__back {
	position: relative;
	top: 11rem;
	left: -3rem;
	cursor: pointer;
	z-index: 5;

	.show {
		display: block;
	}

	& i {
		color: #878787;
	}
}
