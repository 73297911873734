// TEMPLATE STYLES

// bootstrap overrides only (taken from variables.scss)
// hint: write values without !default

// Color system
$primary: #878787;
$secondary: #7A6650;

$gray: #eee;
$text-color: #333333;

$danger: #df5765;
$success: #52b669;

$darkenColor: darken($primary, 8%);

// Color building and rooms
$building-color: $primary;
$room-free-color: $success;
$room-reserved-color: #ffffa7;
$room-sold-color: $danger;

// Spacing
$spacer: 1rem;

$spacers: ();

@for $i from 0 through 12 {
	$spacers: map-merge($spacers, ($i: $i * 1rem));
}

$sp-section: 10rem;
$sp-footer: 10rem;
$sp-copyright: 1rem;

$sp-section-mobile: $sp-section / 2;

$space-img: 3rem;

$sp-paragraph: 3.5rem;
$sp-heading: 8rem;

$sp-heading-mobile: $sp-heading / 2;
$sp-paragraph-mobile: 1.75rem;

// Body
$body-bg: white;
$body-color: #222;

// Links
$link-color: $text-color;
$link-decoration: none;
$link-hover-color: darken($link-color, 15%);
$link-hover-decoration: none;

// Components
$border-width: .1rem;
$border-color: #dee2e6;
$border-radius: 0rem;

// Paragraphs
$paragraph-margin-bottom: $sp-paragraph;

// Fonts
@import url('https://fonts.googleapis.com/css?family=Lato:300,400,700,900&display=swap&subset=latin-ext');
$font-family-sans-serif: 'Lato',
sans-serif;

$font-size-base: 1.6rem; // Assumes the browser default, typically `16px`
$line-height-base: 1.2;

$h1-font-size: $font-size-base * 2.81;
$h2-font-size: $font-size-base * 2;
$h3-font-size: $font-size-base * 1.75;
$h4-font-size: $font-size-base * 1.5;
$h5-font-size: $font-size-base * 1.25;
$h6-font-size: $font-size-base;

$headings-margin-bottom: $sp-heading;
$headings-font-weight: 400;
$headings-line-height: 1.35;

// Buttons + Forms
$input-btn-padding-y: 1.5rem;
$input-btn-padding-x: 4rem;
$input-btn-font-family: null;
$input-btn-font-size: $font-size-base;
$input-btn-line-height: $line-height-base;

$input-btn-focus-width: .2rem;
$input-btn-focus-color: rgba($primary, .25);
$input-btn-focus-box-shadow: 0 0 0 $input-btn-focus-width $input-btn-focus-color;

$input-btn-border-width: $border-width;

// Buttons
$btn-font-weight: 700;
$btn-box-shadow: inset 0 0.1rem 0 rgba(white, .15),
0 0.1rem 0.1rem rgba(black, .075);
$btn-disabled-opacity: .65;
$btn-active-box-shadow: inset 0 0.3rem 0.5rem rgba(black, .125);
$btn-link-disabled-color: #6c757d;

$btn-border-radius: $border-radius;

$btn-margin-top: 7rem;

// Forms
$label-margin-bottom: .5rem;
$input-padding-y: $input-btn-padding-y;
$input-padding-x: $input-btn-padding-x;
$input-line-height: $input-btn-line-height;
$input-bg: white;
$input-disabled-bg: #e9ecef;
$input-color: $primary;
$input-border-color: $primary;
$input-border-width: $input-btn-border-width;
$input-box-shadow: inset 0 0.1rem 0.1rem rgba(black, .075);
$input-border-radius: $border-radius;
$input-focus-bg: $input-bg;
$input-focus-border-color: lighten($primary, 25%);
$input-focus-color: ighten($primary, 25%);
$input-focus-width: $input-btn-focus-width;
$input-focus-box-shadow: $input-btn-focus-box-shadow;
$input-placeholder-color: #6c757d;
$input-plaintext-color: $body-color;
$input-height-border: $input-border-width * 2;
$form-text-margin-top: 0;

$custom-control-indicator-size: 1.5rem;

// Form validation
$form-feedback-margin-top: $form-text-margin-top;
$form-feedback-font-size: 80%;
$form-feedback-valid-color: $success;
$form-feedback-invalid-color: $danger;

// Z-index master list
// Warning: Avoid customizing these values. Just take a look at values.
$zindex-dropdown: 1000 !default;
$zindex-sticky: 1020 !default;
$zindex-fixed: 1030 !default;
$zindex-modal-backdrop: 1040 !default;
$zindex-modal: 1050 !default;
$zindex-popover: 1060 !default;
$zindex-tooltip: 1070 !default;

// Navs
$nav-link-padding-y: .5rem;
$nav-link-padding-x: 1rem;
$nav-link-disabled-color: #6c757d;

// Navbar
$navbar-padding-y: 2rem;
$navbar-padding-x: 0;
$navbar-nav-link-padding-x: .5rem;

// Dropdowns and nav dropdowns
$dropdown-min-width: 10rem;
$dropdown-padding-y: .5rem;
$dropdown-spacer: .125rem;
$dropdown-bg: white;
$dropdown-border-color: rgba(black, .15);
$dropdown-border-radius: $border-radius;
$dropdown-border-width: $border-width;
$dropdown-box-shadow: 0 .5rem 1rem rgba(black, .175);

$dropdown-link-color: $primary;
$dropdown-link-hover-color: darken($primary, 15%);
$dropdown-link-hover-bg: #eee;

$dropdown-link-active-color: white;
$dropdown-link-active-bg: $primary;

$dropdown-item-padding-y: .25rem !default;
$dropdown-item-padding-x: 1.5rem !default;

// Breadcrumbs
$breadcrumb-padding-y: 19rem;
$breadcrumb-padding-x: 0;
$breadcrumb-item-padding: .5rem;
$breadcrumb-margin-bottom: 0;
$breadcrumb-bg: transparent;
$breadcrumb-border-radius: $border-radius;

// Carousel
$carousel-control-color: white;
$carousel-control-width: 15%;
$carousel-control-opacity: .5;

$carousel-indicator-width: 3rem;
$carousel-indicator-height: 0.3rem;
$carousel-indicator-spacer: 0.3rem;
$carousel-indicator-active-bg: white;

$carousel-caption-color: white;

// Tables
$card-spacer-y: 0;
$card-spacer-x: 0;
$card-cap-bg: $primary;
$card-cap-colo: white;

// Pagination
// Cards
// Tooltips
// Popovers
// Modals
// Close
// List group

// You can copy more from scss/vendors/bootstrap/_variables.scss


// Svg colors
$blueSvg: #6666ff;
$orangeSvg: rgb(90, 73, 41);
$magentaSvg: rgb(140, 95, 177);
$cyanSvg: rgb(185, 185, 185);
